import { StaticImage } from "gatsby-plugin-image";
import React, {useRef, useEffect, createRef} from "react";
import { Seo } from "../../components/Seo";

const BachEyewearLanding = () => {
  const textRef = useRef([]);
  const imageRef = useRef([]);
  for(let i = 0; i < 2; i++) {
    textRef.current[i] = createRef();
    imageRef.current[i] = createRef();
  }

  const resizeSection = () => {
    for(const[k,i] of Object.entries(textRef.current)) {
      if(i.current || false) {
        let mheight = i.current.offsetHeight + 40;
        imageRef.current[k].current.style.maxHeight = `${mheight}px`;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  return (
    <main>
      <section className="py-24">
        <div className="xl:container mx-auto px-6 grid lg:grid-cols-2 items-center gap-x-12 gap-y-12">
          <div className="flex h-full">
            <StaticImage src="../../images/Sourci_ 455-975w.jpg" 
              alt=""
              placeholder="blurred"
              className="rounded-3xl" />
          </div>
          <div>
            <p className="font-black text-3xl lg:text-4xl">
            Started just over 4 years ago in 2017 by founders Giovanni Pino and Ellie Vaisman, Sourci imported over $100m of consumer goods in the last 2 years alone. Here's how we worked with Bach to manufacture their eyewear range and take the brand from strength to strength.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[0]}
            className="py-24 px-16 xl:px-32">
            <h1 className="font-black text-4xl lg:text-5xl mb-12">
              Bach Eyewear.<br />
              Where Fashion & Safety Meet.<br />
              Manufactured in China.<br />
            </h1>
            <p className="text-xl lg:text-2xl mb-8">
              Bach Eyewear set out to change the way the trade industry sees eyewear. The goal was to design and create a range of protective sunglasses that could be used in everyday life but that also met Australian AS/NZ Safety Certification making them safe for any tradie to wear on any job site. With Sourci's help over 9 months of product development, sampling and production, we finally had the first drop of Bach Eyewear and they look incredible, see for yourself!
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Several months later we had a finished product that Simon was beyond happy with. Sourci then organised independent testing of the glasses at a third party testing facility to ensure they complied with the required AUS/NZ Safety Standards.
            </p>
            <p className="font-bold text-xl lg:text-2xl">Here's how we made amazing happen for Bach Eyewear.</p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[0]}>
              <StaticImage src="../../images/bach-eyewear-1.jpg"
                alt=""
                className="object-cover min-w-[100%]"
                placeholder="blurred" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container grid-cols-1 lg:grid-cols-4 mx-auto px-6 grid text-center gap-x-12 gap-y-12">
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>4</span>
            </p>
            <p className="font-black text-xl">Product Range</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>AUS/NZ</span>
            </p>
            <p className="font-black text-xl">SAFETY CERTIFICATION</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>9</span>
            </p>
            <p className="font-black text-xl">MONTHS SAVED</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>&#8593;$60,000</span>
            </p>
            <p className="font-black text-xl">OF SAVINGS</p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div className="flex h-full"
            ref={imageRef.current[1]}>
              <StaticImage src="../../images/bach-eyewear-3-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
          <div ref={textRef.current[1]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
            Fast-tracked Sampling & Production
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              Bach Eyewear's goal was to launch in early 2021, with Sourci’s team aligning with the perfect manufacturing partner, we estimate that we were able to save 4-6 months of development time by producing multiple components of the glasses at the same time through separate manufacturing partners, and consolidating them through our primary factory - this produced samples in half the time and saved 2 months of additional manufacturing time.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              With Simon's full focus now on marketing, he was able to create and execute an explosive launch campaign, and create magical momentum.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Successful E-commerce founders are marketing and selling extraordinaires. They don't need to get bogged down dealing with product & supply chain - leave making amazing products to the experts and thrive with full focus on your growth.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              It's time to see the world through a beautiful new lens.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Want a pair for yourself? <a href="https://www.bacheyewear.com/"
                className="text-sourci-teal hover:underline"
                target="_blank"
                rel="noreferrer">Visit their website here</a>.
            </p>
            <p className="font-bold text-xl lg:text-2xl">You're in charge here.</p>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-4xl lg:text-5xl mb-12">
            See if we can help with your product
          </h3>
          <a href="https://sourci.leadshook.io/survey/Snar5vhx9dVnFUtuv0dYztx5DEfYI2Pma7csY19s"
            rel="noreferrer noopener"
            target="_blank"
            className="text-white relative rounded-xl justify-center px-12 py-4 whitespace-nowrap bg-sourci-teal hover:bg-sourci-teal-100 text-xl transition-colors">
            Get Started {'>'}
          </a>
        </div>
      </section>

    </main>
  )
}

export default BachEyewearLanding;

export function Head() {
  return (
    <Seo title="Bach Eyewear | Sourci"
      description="Bach Eyewear's goal was to launch in early 2021, with Sourci's team aligning with the perfect manufacturing partner, we estimate that we were able to save 4-6 months of development time"
      canonical="/lp/bach-eyewear" />
  )
}